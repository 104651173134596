<template>
  <div class="parameter-subsidiary">
    <!-- 查询 -->
    <base-form
      :componentList="formConfig"
      :formAttrs="{
        model: queryParas,
        labelWidth: '120px',
      }"
      class="formStyle"
      @handleFilter="handleFilter"
      @clearParams="clearParams"
    />
    <!-- 放款金额&文件导出 -->
    <div class="main-page-table__header flex-between">
      <span>放款金额(元)：{{ loanAmount || 0 }}</span>
      <base-button
        label="导出"
        @click="fileDownload()"
        icon="iconfont icondaochu"
      />
    </div>
    <!-- 列表 -->
    <base-table
      class="main-page-table with-add"
      :columns="columns"
      :showPage="true"
      :tableAttrs="{
        data: tableData,
        stripe: true,
      }"
      ref="tableData"
      :api="api"
      :getApi="'getPage'"
      :loadCount="loadCount"
      :queryParas.sync="queryParas"
      :dataSource.sync="tableData"
      :webPage="false"
    >
      <template slot="index" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
    </base-table>
  </div>
</template>
<script>
import baseForm from '@/components/common/base-form//base-form.vue'
import { dataForm, dataTable } from './config'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import { parameterApi } from '@/api/parameterApi'
import { formatDate } from '@/utils/auth/common'
import { getManager } from '@/api/sys/user'
import { getDictLists } from '@/filters/fromDict'
export default {
  name: 'parameterSubsidiary',
  components: { baseForm, BaseTable, BaseButton },
  data () {
    return {
      queryParas: {
        pageSize: 10,
        pageIndex: 1,
        capitalSideId: ''
      },
      loanAmount: '',
      tableData: [],
      loadCount: 0,
      list: [],
      ProductList: [],
      oneSupplierList: [],
      twoSupplierList: [],
      busiManagerOptions: [], // 业务经理list
      capitalTypeOPtions: [] // 资金类型
    }
  },
  computed: {
    formConfig () {
      return dataForm(this)
    },
    columns () {
      return dataTable(this)
    },
    api () {
      return parameterApi
    },
    // 资金方类型
    capitalSideTypeOptions () {
      return getDictLists('CAPITAL_TYPE')
    }

  },
  mounted () {
    Promise.all([
      this.handleFilter(),
      this.loanAmountSum(),
      this.getOnelistData(),
      this.getProductList(),
      this.getBusinessMangeer(),
      this.getCapital()
    ])
  },
  methods: {
    // getTableList () {
    //   parameterApi.getTableList(this.queryParas).then(res => {
    //     console.log(res.data.records)
    //     this.tableData = res.data.records
    //   }).catch(err => {
    //     console.log(err)
    //   })
    // },
    handleFilter () {
      this.queryParas.pageIndex = 1
      this.loadCount++
      this.loanAmountSum()
    },
    // 资金方下拉
    getCapital () {
      this.api
        .getFundsprovidertName(this.queryParas.capitalSideType || '')
        .then((res) => {
          if (res.success) {
            this.list = res.data.map((item, index) => {
              return { name: item, index: index }
            })
          }
        })
    },
    // 查询一级供应商下拉
    getOnelistData () {
      this.api.getOneList().then((res) => {
        if (res.success) {
          this.oneSupplierList = res.data
        }
      })
    },
    // 获取业务经理下拉
    getBusinessMangeer () {
      getManager({ roleCode: 'ywjl_code' }).then((res) => {
        this.busiManagerOptions = res.data.map((item) => {
          // return { busiManagerId: item.userId, busiManagerName: item.nickName }
          return { busiManagerId: item.userId, busiManagerName: item.nickName }
        })
      })
    },
    // 查询二级供应商下拉
    getTwoListData (id) {
      this.$set(this.queryParas, 'secondSupplier', '')
      this.twoSupplierList = []
      if (id) {
        this.api.getTwoList(id).then((res) => {
          if (res.success) {
            this.twoSupplierList = res.data.map((item) => {
              return { keyId: item.keyId, enterpriseName: item.mainBorrower }
            })
          }
        })
      }
    },
    // 查询产品下拉
    getProductList () {
      this.api
        .getProductList()
        .then((res) => {
          if (res.data) {
            this.ProductList = res.data
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    // 融资台账总的还款金额
    loanAmountSum () {
      this.api.loanAmountSum(this.queryParas).then((res) => {
        if (res.success) {
          this.loanAmount = parseFloat(res.data).toLocaleString('en-US', {
            minimumFractionDigits: 2
          })
        }
      })
    },
    // 重置
    clearParams () {
      this.queryParas = {
        pageSize: 10,
        pageIndex: 1
      }
      this.twoSupplierList = []
    },
    // 文件导出
    fileDownload () {
      this.api
        .exportList(this.fromParams())
        .then((res) => {
          if (res.data.type === 'application/json') {
            this.error('融资台账查询结果为空')
            return
          }
          const url = URL.createObjectURL(res.data)
          const a = document.createElement('a')
          a.href = url
          a.download = '融资台账明细.xlsx'
          a.click()
          URL.revokeObjectURL(url)
        })
        .catch((error) => {
          console.log('表格导出错误', error)
        })
    },
    // 参数处理
    fromParams () {
      const params = { ...this.queryParas }
      // 判空
      const isLendingdata = Object.prototype.hasOwnProperty.call(
        params,
        'Lendingdata'
      )
      if (isLendingdata) {
        params.loanStart = formatDate(params.Lendingdata[0], 'YY-MM-DD')
        params.loanEnd = formatDate(params.Lendingdata[1], 'YY-MM-DD')
        delete params.Lendingdata
        return params
      } else {
        return params
      }
    }
  }
}
</script>
<style lang="scss" src='./index.scss' scoped />
