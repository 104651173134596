import BaseSelect from '@/components/common/base-select/base-select.vue'
import { formatDate } from '@/utils/auth/common'

// 查询form
export const dataForm = (content) => {
  return [
    {
      label: '资金类型',
      prop: 'capitalType',
      attrs: {
        options: content.capitalSideTypeOptions,
        selectedField: ['dictId', 'dictName'],
        placeholder: '请选择'
      },
      tag: BaseSelect
    },
    {
      label: '资金方',
      prop: 'capitalSideName',
      attrs: {
        options: content.list,
        selectedField: ['name', 'name'],
        placeholder: '请选择'
      },
      tag: BaseSelect
    },
    {
      label: '供应商',
      isHidden: content.fold,
      prop: 'firstSupplier',
      attrs: {
        options: content.oneSupplierList,
        selectedField: ['keyId', 'enterpriseName'],
        placeholder: '请选择',
        filterable: true
      },
      tag: BaseSelect,
      on: {
        change: (data) => {
          if (data) {
            content.getTwoListData(data)
            const [obj] = content.oneSupplierList.filter(item => item.keyId === data)
            content.queryParas.firstSupplier = obj ? obj.enterpriseName : ''
          }
        },
        clear: (data) => {
          content.getTwoListData()
        }
      }
    },
    // {
    //   label: '二级供应商',
    //   isHidden: content.fold,
    //   prop: 'secondSupplier',
    //   disabled: true,
    //   attrs: {
    //     options: content.twoSupplierList,
    //     selectedField: ['keyId', 'enterpriseName'],
    //     placeholder: '请选择',
    //     filterable: true
    //   },
    //   tag: BaseSelect,
    //   on: {
    //     change: (data) => {
    //       if (data) {
    //         const [obj] = content.twoSupplierList.filter(item => item.keyId === data)
    //         content.queryParas.secondSupplier = obj ? obj.enterpriseName : ''
    //       }
    //     }
    //   }
    // },
    {
      label: '产品',
      prop: 'product',
      isHidden: content.fold,
      attrs: {
        options: content.ProductList,
        selectedField: ['productName', 'productName'],
        placeholder: '请选择'
      },
      tag: BaseSelect
    },
    {
      label: '借款合同编码',
      prop: 'loanContractNo',
      isHidden: content.fold,
      attrs: {
        placeholder: '请输入'
      }
    },
    {
      label: '放款日',
      prop: 'Lendingdata',
      isHidden: content.fold,
      attrs: {
        type: 'daterange',
        rangeSeparator: '至',
        startPlaceholder: '开始日期',
        endPlaceholder: '结束日期'
      },
      tag: 'el-date-picker',
      on: {
        change: (data) => {
          content.queryParas.loanEnd = formatDate(data[1], 'YY-MM-DD')
          content.queryParas.loanStart = formatDate(data[0], 'YY-MM-DD')
        }
      }
    },
    {
      label: '业务经理',
      prop: 'businessManager',
      isHidden: content.fold,
      attrs: {
        placeholder: '请选择',
        options: content.busiManagerOptions,
        selectedField: ['busiManagerId', 'busiManagerName']
      },
      tag: BaseSelect
    }
  ]
}
// 表格form
export const dataTable = (content) => {
  return [
    {
      label: '序号',
      prop: 'index',
      width: '60px'
    },
    {
      label: '资金类型',
      prop: 'capitalType',
      formatter: row => {
        const [obj] = content.capitalSideTypeOptions.filter(item => row.capitalType === item.dictId)
        return obj ? obj.dictName : ''
      }
    },
    {
      label: '资金方',
      prop: 'capitalSideName',
      'show-overflow-tooltip': true
    },
    {
      label: '产品',
      prop: 'product',
      'show-overflow-tooltip': true
    },
    {
      label: '供应商',
      prop: 'firstSupplier',
      'show-overflow-tooltip': true
    },
    // {
    //   label: '二级供应商',
    //   prop: 'secondSupplier',
    //   'show-overflow-tooltip': true
    // },
    // {
    //   label: '个人借款人名称',
    //   prop: 'personnalBorrower'
    // },
    // {
    //   label: '共同借款人',
    //   prop: 'coBorrower'
    // },
    {
      label: '放款金额(元)',
      prop: 'loanAmount',
      // formatter: row => {
      //   return (row.loanAmount ? row.loanAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '')
      // },
      'show-overflow-tooltip': true,
      align: 'right'
    },
    {
      label: '供应商利率(%)',
      prop: 'supplierRate',
      formatter: row => {
        return row.supplierRate.toFixed(2)
      },
      align: 'right'
    },
    {
      label: '资金方利率(%)',
      prop: 'capitalSideRate',
      formatter: row => {
        return row.capitalSideRate.toFixed(2)
      },
      align: 'right'
    },
    {
      label: '融资期限(月)',
      prop: 'financeTerm',
      'show-overflow-tooltip': true
    },
    {
      label: '放款日',
      prop: 'startDate',
      formatter: row => {
        return formatDate(row.startDate, 'YY-MM-DD')
      },
      'show-overflow-tooltip': true
    },
    {
      label: '到期日',
      prop: 'endDate',
      formatter: row => {
        return formatDate(row.endDate, 'YY-MM-DD')
      },
      'show-overflow-tooltip': true
    },
    {
      label: '借款合同编号',
      prop: 'loanContractNo',
      'show-overflow-tooltip': true
    },
    {
      label: '实际还款金额',
      prop: 'actualRepayAmount',
      formatter: row => {
        return (row.actualRepayAmount ? row.actualRepayAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '')
      },
      align: 'right',
      'show-overflow-tooltip': true
    },
    {
      label: '实际还款日期',
      prop: 'actualRepayDate',
      formatter: row => {
        return formatDate(row.actualRepayDate, 'YY-MM-DD')
      },
      'show-overflow-tooltip': true

    },
    {
      label: '业务经理',
      prop: 'businessManager',
      'show-overflow-tooltip': true,
      formatter: row => {
        const [obj] = content.busiManagerOptions.filter(item => row.businessManager === item.busiManagerId)
        return obj ? obj.busiManagerName : ''
      }
    }
  ]
}
